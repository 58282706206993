<template>
  <div>
    <el-menu
      :default-active="$route.path"
      :router="true"
      class="el-menu-vertical-demo"
      @open="handleOpen"
      @close="handleClose"
      :collapse="isCollapse"
    >
      <template v-for="item in asidebarData">
        <el-submenu v-if="item.children" :key="item.index" :index="item.index">
          <template v-if="item.children">
            <template slot="title">
              <i :class="item.icon"></i>
              <span>{{ item.title }}</span>
            </template>
            <template v-if="item.children">
              <el-menu-item
                v-for="childrenitem in item.children"
                :key="childrenitem.index"
                :index="childrenitem.index"
              >
                {{ childrenitem.title }}
              </el-menu-item>
            </template>
          </template>
        </el-submenu>

        <template v-if="!item.children">
          <el-menu-item :index="item.index" :key="item.index">
            <i :class="item.icon"></i>
            <span slot="title">{{ item.title }}</span>
          </el-menu-item>
        </template>
      </template>
    </el-menu>

    <div class="fixBottomOpen" @click="closeMenu" v-if="!this.isCollapse">
      <i class="el-icon-s-fold"></i>
    </div>

    <div class="fixBottomClose" @click="openMenu" v-else>
      <i class="el-icon-s-unfold"></i>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isCollapse: false,
      asidebarData: [
        {
          index: "1",
          title: "学生管理",
          icon: "el-icon-s-platform",
          children: [
            { index: "/Login", title: "新增学生" },
          ],
        },
        {
          index: "/menu",
          title: "登录",
          icon: "el-icon-s-platform",
        },
        {
          index: "/user",
          icon: "el-icon-s-ticket",
          title: "用户管理",
        },
        {
          index: "2",
          title: "班级管理",
          icon: "el-icon-s-platform",
          children: [
            { index: "/home/classeslist", title: "班级列表" },
            { index: "/home/classesAdd", title: "新增班级" },
          ],
        },
        {
          index: "3",
          icon: "el-icon-s-check",
          title: "班级管理",
        },
      ],
    };
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    closeMenu() {
      this.isCollapse = true;
    },
    openMenu() {
      this.isCollapse = false;
    },
  },
};
</script>

<style>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  min-height: calc(100vh - 146px);
  width: 175px;
}
.fixBottomOpen {
  height: 56px;
  line-height: 56px;
  font-size: 14px;
  color: #303133;
  padding: 0 20px;
  list-style: none;
  cursor: pointer;
  position: relative;
  transition: border-color 0.3s, background-color 0.3s, color 0.3s;
  box-sizing: border-box;
  white-space: nowrap;
  background-color: white;
  width: 175px;
  font-size: 18px;
}
.fixBottomClose {
  height: 56px;
  line-height: 56px;
  font-size: 14px;
  color: #303133;
  padding: 0 20px;
  list-style: none;
  cursor: pointer;
  position: relative;
  transition: border-color 0.3s, background-color 0.3s, color 0.3s;
  box-sizing: border-box;
  white-space: nowrap;
  background-color: white;
  width: 64px;
  font-size: 18px;
}
</style>