<template>
  <div class="login">
   登录
  </div>
</template>

 

<script>

export default {

  data() {

    return {

      openId: '',

      userId: ''

    }

  },

  created() {

    this.getloginInfo()
  console.log('执行了');
  },

  methods: {

    async getloginInfo() {

      const AppId = 'wxe4909a3878c45fcb' // 公众号的AppId

      const code = this.getUrlParam('code') // 获取请求路径中带code字段参数的方法

       const local ='https://www.eksaas.com/login/index.html' // 获取当前的页面路径，这就是回调的地址

      if (code == null || code === ' ') {
          console.log('这是本地'+local);
          console.log('11111111111111111111111');
          window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + AppId + '&redirect_uri=' + encodeURIComponent(local) + '&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect'

          //  window.location.href = `https://api.weixin.qq.com/sns/oauth2/access_token?appid=${AppId}&secret=3c9546e4d9a2d7d811a4c6c57f042405&code=031n3J0w3izEKY2zc40w341Oze2n3J0y&grant_type=authorization_code`
               
     
       } else {
          console.log('这是'+code);
         this.getOpenId(code) // 通过获取到的code，调用后台的接口，取得openId

      }

    },

 

    // 获取页面路径的code参数

     getUrlParam(name) { // 获取URL指定参数

      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)') // 构造一个含有目标参数的正则表达式对象

       var r = window.location.search.substr(1).match(reg) // 匹配目标参数
       console.log( r);

       if (r != null) return unescape(r[2])

      return null // 返回参数值
       
     },

 

    // 把code传给后台

    async getOpenId(code) { // 通过code获取openId等用户信息

       const res = await this.$api.user.login({

         method: 'POST',
          //  URL:"/api/test/wx",
        params: {

           code: code // 根据后台要求的参数填写

         }

      })

      console.log(res.data)

    }

  }

}

</script>

 

<style >

</style>
