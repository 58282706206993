import axios from 'axios'
const user={
      login(data){
        return axios({
         url:"http://192.168.1.254:8080/api/test/wx",
         method:'POST',
         data
        })
      }
}
export default user