import api from "../../api";
export default{
    namespaced:true,
    state:{
        rows: [],
        pages: 0,
        total: 0
    },
    mutations:{
        SET_STUDENTS(state,payload){
           Object.assign(state,payload)
        }
    },
    actions:{
      async  getStudentsAsync(context,payload){
          const data=  await api.students.getStudents(payload)
          context.commit('SET_STUDENTS',data.data.data)
        //   console.log(data.data.data);
        }
    }
}