<template>
  <WangEditor></WangEditor>
</template>

<script>
import WangEditor from "../../components/wangEditor/wangEditor.vue";
export default {
  components: {
    WangEditor,
  },
};
</script>

<style>
</style>