<template>
  <div class="subcontainer">
    <!-- de -->
    <div class="editor" id="editor" ref="editor"></div>
    <div class="a-btn" @click="ok">
      <a href="javascript:void(0)">提交</a>
    </div>
  </div>
</template>

<script>
import E from "wangeditor";

export default {
  data() {
    return {
      activeName: "first",
      txt: {
        text: "9090",
      },

      editor: "", // 存放实例化的wangEditor对象，在多个方法中使用
    };
  },

  mounted() {
    this.editor = new E("#editor"); //new即可
    console.log(this.editor);
    this.editor.config.uploadImgShowBase64 = false; // base 64 存储图片
    this.editor.config.uploadImgServer =
      " common.requestUrl " + "/file/upload/1?type=1&fn=educiot.png"; // 配置服务器端地址
    this.editor.config.uploadImgHeaders = {}; // 自定义 header
    this.editor.config.uploadFileName = "file"; // 后端接受上传文件的参数名
    this.editor.config.uploadImgMaxSize = 10 * 1024 * 1024; // 将图片大小限制为 10M
    // this.editor.config.uploadImgMaxLength = 6; // 限制一次最多上传 3 张图片
    this.editor.config.uploadImgTimeout = 3 * 60 * 1000; // 设置超时时间
    //下面的为一些配置参数，默认全部都有，我们需要那些留下那些即可
    this.editor.config.menus = [
      "head", // 标题
      "bold", // 粗体
      "fontSize", // 字号
      "fontName", // 字体
      "italic", // 斜体
      "underline", // 下划线
      "strikeThrough", // 删除线
      "foreColor", // 文字颜色
      "backColor", // 背景颜色
      "link", // 插入链接
      "list", // 列表
      "justify", // 对齐方式
      // 'quote',  // 引用
      "emoticon", // 表情
      "image", // 插入图片
      "table", // 表格
      "video", // 插入视频
      // "code", // 插入代码
      "undo", // 撤销
      "redo", // 重复
    ];
    //聚焦时候函数
    this.editor.config.onfocus = function () {
      //console.log("onfocus")
    };
    this.editor.config.onblur = function () {
      //console.log("onblur")
    };
    //自定义编辑器 alert ,使用elemnent-ui的message
    this.editor.config.customAlert = (s, e) => {
      switch (e) {
        case "success":
          this.$message.success(s);
          break;
        case "info":
          this.$message.info(s);
          break;
        case "warning":
          this.$message.warning(s);
          break;
        case "error":
          this.$message.error(s);
          break;
        default:
          this.$message.info(s);
      }
    };
    //change事件，当富文本编辑器内容发生变化时便会触发此函数
    // this.editor.config.onchange = function (text) {
    //   console.log(text);
    // };
    this.editor.create(); //以上配置完成之后调用其create()方法进行创建
    this.editor.txt.html("请输入内容"); //创建完成之后的默认内容
  },

  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    //点击事件，拿到富文本编辑器里面的值
    ok() {
      //富文本编辑器里面的内容我们可以输出为html（布局）格式，也可以输出位text（文本）格式
      console.log(this.editor.txt.html());
    },
  },
};
</script>

<style scoped>
.subcontainer {
  height: 100%;
  width: 100%;
}
.subcontainer .tabs {
  padding: 20px 0;
}
.editor {
  width: 100%;
  height: 300px;
  margin-bottom: 40px;
}
.a-btn {
  padding-bottom: 80px;
}
.a-btn a {
  display: block;
  color: #fff;
  font-size: 16px;
  line-height: 30px;
  width: 100px;
  text-align: center;
  float: right;
  background: dodgerblue;
}
</style>