<template>
  <el-container>
    <el-header height="90px"> {{rows}}</el-header>
    <el-container>
      <el-aside max-width='200px' width="normal">
       <AsideMenu></AsideMenu>  
      </el-aside>
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import AsideMenu from "../../components/asideMenu/asideMenu.vue";

import {createNamespacedHelpers } from "vuex";
const {
mapState:StudentMapState,
mapActions:StudentMapActions
}=createNamespacedHelpers('students')
export default {
  components: {
    AsideMenu,
  },
  data() {
    return {
    }
  },
   mounted(){
      this.getloginInfo()
      console.log('执行了验证');

   },
  methods: {

    async getloginInfo() {
      
      const AppId = 'wxe4909a3878c45fcb' // 公众号的AppId

      const code = this.getUrlParam('code') // 获取请求路径中带code字段参数的方法

       const local ='http://www.eksaas.com/login/index.html' // 获取当前的页面路径，这就是回调的地址

      if (code == null || code === ' ') {

          window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + AppId + '&redirect_uri=' + encodeURIComponent(local) + '&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect'


          //  window.location.href = `https://api.weixin.qq.com/sns/oauth2/access_token?appid=${AppId}&secret=3c9546e4d9a2d7d811a4c6c57f042405&code=011x2Zll2LSeo94bYtol2TVVr30x2ZlM&grant_type=authorization_code`
               
       } else {
          console.log('这是'+code);
         this.getOpenId(code) // 通过获取到的code，调用后台的接口，取得openId

      }

    },

 

    // 获取页面路径的code参数

     getUrlParam(name) { // 获取URL指定参数

      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)') // 构造一个含有目标参数的正则表达式对象

       var r = window.location.search.substr(1).match(reg) // 匹配目标参数
       console.log('结果'+ r);

       if (r != null) return unescape(r[2])

      return null // 返回参数值

     },

 

    // 把code传给后台

    async getOpenId(code) { // 通过code获取openId等用户信息

       const res = await this.$api.user.login({  code})
      console.log(res.data)
      console.log('这是code'+code)

    }

  }
};
</script>

<style>
.el-header {
  background-color: #b3c0d1;
  color: #333;
  text-align: center;
}

.el-aside {
  background-color: #d3dce6;
  color: #333;
  text-align: center;
  height: calc(100vh - 90px);
  overflow: hidden;
}

.el-main {
  background-color: #e9eef3;
  color: #333;
  
  text-align: center;
  height: calc(100vh - 90px);
}
</style>