import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import api from './api'
import axios from 'axios'
//设置默认网址
// axios.defaults.baseURL="http://47.98.128.191:3000"
//api挂载全局
Vue.prototype.$api=api

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
